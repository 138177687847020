import React from "react"

//React components imports
import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <p>Je nám líto, ale stránka nebyla nalezena.</p>
    </Layout>
  )
}

export default NotFoundPage
